<style lang="scss" scoped>
.user-section {
  .row {
    @apply  relative rounded-xl px-4 mb-4 items-center py-4;
    @screen md {
      @apply  flex relative shadow-md ;
    }
    &.selected {
      box-shadow: 1px 1px 6px 0px var(--color-primary);
      &::before {
        @apply w-6 h-6 absolute flex items-center justify-center -ml-6 -mt-8 bg-white;
        @apply border-2 border-primary rounded-full;
        @apply text-primary font-bold;
        content: '✓';
      }
    }
    li {
      @apply mb-4 ;
      @screen md {
        @apply flex flex-col mx-4 mb-0;
      }
      strong {
        @apply text-gray-200 uppercase font-normal text-xs tracking-widest mr-2;

      }
    }
  }
}
</style>

<template>
  <article
    class="user-section"
  >
    <credit-card
      v-if="ui.modalActive"
      :is-active="ui.modalActive"
      @closed="$event ? fetch() : '' ; ui.modalActive = false"
    />

    <h1 class="text-secondary">
      Meus cartões
    </h1>

    <button
      class="button button--blank ml-auto mb-4 mt-0 transform -mr-6 scale-75"
      @click="ui.modalActive = !ui.modalActive"
    >
      <big>+</big> Adicionar cartão
    </button>

    <h2
      v-if="!ui.isLoading && cardList.length === 0"
      class="text-neutral text-center"
    >
      Nenhum cartão cadastrado
    </h2>

    <transition-group
      v-else
      name="fade"
      class="w-full"
      track-by="card.id"
    >
      <ul
        v-for="card in cardList"
        :key="card.id"
        class="row"
        :class="card.is_default ? 'selected' : ''"
      >
        <li>
          <img
            class="w-20"
            :src="cardBrand(card.brand)"
            :alt="card.brand"
          >
        </li>

        <li class="!m-auto">
          <strong>Número</strong>
          <span>Final {{ card.last_digits }}</span>
        </li>

        <li class="!m-auto">
          <strong>Expira em</strong>
          <span>{{ card.expiration_date.substr(0,2) }}/{{ card.expiration_date.substr(2,3) }}</span>
        </li>

        <li>
          <strong>Nome no cartão</strong>
          <span>{{ card.holder_name }}</span>
        </li>

        <li class="!m-auto w-20 text-right !inline">
          <ui-icon
            class="h-8 p-1 inline-block cursor-pointer"
            name="alert"
            color="red"
            @click.native="removeCard(card.id)"
          />

          <ui-icon
            v-if="!card.is_default"
            class="h-8 mt-2 inline-block cursor-pointer"
            name="check"
            color="#51db81"
            fill="#51db81"
            @click.native="setDefaultCard(card.id)"
          />
        </li>
      </ul>
    </transition-group>
  </article>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import payment from '@/api/user/payment';
import handleError from '@/mixins/handleError';
import handleSuccess from '@/mixins/handleSuccess';
import visa from '@/assets/img/logo/credit_cards/visa.png';
import master from '@/assets/img/logo/credit_cards/mastercard.png';
import elo from '@/assets/img/logo/credit_cards/elo.png';
import american from '@/assets/img/logo/credit_cards/americaExpress.png';
import hipercard from '@/assets/img/logo/credit_cards/hipercard.png';
import genericBrand from '@/assets/img/logo/credit_cards/genericCard.png';
import UiIcon from '@/components/ui/Icon.vue';
import CreditCard from '@/components/modals/CreditCard.vue';

export default {
  name: 'UserPayment',

  components: {
    UiIcon,
    CreditCard,
  },

  mixins: [handleError, handleSuccess],

  data() {
    return {
      payment: null,
      cardList: [],
      ui: {
        loaded: true,
        modalActive: false,
        isLoading: false,
      },
      rememberUser: false,
    };
  },

  computed: {
    ...mapGetters('user', ['headers']),
  },

  mounted() {
    this.payment = payment(this.headers);
    this.fetch();
  },

  methods: {
    ...mapActions('ui', [
      'toggleProgressBar',
      'openModalDialog',
      'closeModalDialog',
    ]),

    previewImage(file) {
      this.loadedImage = true;
      this.uploadForm.profileImage.value = file;
    },

    fetch() {
      this.ui.isLoading = true;
      this.toggleProgressBar(true);

      this.payment
        .list()
        .then((res) => {
          this.cardList = res;

          for (const card in this.cardList) {
            if (this.cardList[card].is_default) {
              const defaultCard = this.cardList[card];
              this.cardList.splice(card, 1);
              this.cardList.unshift(defaultCard);
            }
          }
        })
        .catch((err) => this.handleError(err))
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });
    },

    cardBrand(brand) {
      switch (true) {
        case brand === 'visa':
          return visa;

        case brand === 'mastercard':
          return master;

        case brand === 'elo':
          return elo;

        case brand === 'amex':
          return american;

        case brand === 'hipercard':
          return hipercard;

        default:
          return genericBrand;
      }
    },

    removeCard(id) {
      this.openModalDialog({
        title: 'Confirmação',
        type: 'confirmation',
        text: 'Confirma a remoção do cartão?',
        cancelText: 'Não',
        confirmText: 'Sim',
        fn: () => {
          this.ui.isLoading = true;
          this.toggleProgressBar(true);

          this.payment
            .remove(id)
            .catch((err) => this.handleError(err))
            .finally(() => {
              this.closeModalDialog();
              this.fetch();
              this.ui.isLoading = false;
              this.toggleProgressBar(false);
            });
        },
      });
    },

    setDefaultCard(id) {
      this.openModalDialog({
        title: 'Confirmação',
        type: 'confirmation',
        text: 'Deseja definir como cartão padrão?',
        cancelText: 'Não',
        confirmText: 'Sim',
        fn: () => {
          this.ui.isLoading = true;
          this.toggleProgressBar();

          this.payment
            .setAsDefault(id)
            .catch((err) => this.handleError(err))
            .finally(() => {
              this.closeModalDialog();
              this.fetch();
              this.ui.isLoading = false;
              this.toggleProgressBar(false);
            });
        },
      });
    },
  },
};
</script>
