<template>
  <transition
    name="fade"
    mode="in-out"
  >
    <modal
      v-if="isActive"
      class="card_modal"
      size="max-w-sm"
      @close="dismiss"
    >
      <template v-slot:title>
        Adicionar cartão
      </template>

      <template
        v-slot:body
        class="card_modal__body"
      >
        <form class="flex flex-wrap max-w-sm md:px-12 md:py-4">
          <div
            v-for="(field, fieldName) in formData"
            :key="`field-${fieldName}`"
            :class="[
              fieldName === 'number' ? 'flex flex-no-wrap w-full items-center' : '',
              fieldName !== 'number' ? 'w-auto' : '',
              field.class ? field.class : 'w-full',
            ]"
          >
            <template v-if="fieldName === 'number'">
              <ui-field
                :field-name="fieldName"
                :field="field"
                :api-errors.sync="apiErrors"
                :validations="$v.formData"
              />

              <div class="w-12 ml-4">
                <img
                  v-for="(cardType, $index) in cardTypes"
                  :key="$index"
                  :track-by="cardType"
                  :src="cardType"
                  class="w-full"
                >
              </div>
            </template>

            <ui-field
              v-else
              :key="field.name"
              :field-name="fieldName"
              :field="field"
              :api-errors.sync="apiErrors"
              :validations="$v.formData"
              class="w-full"
            />
          </div>

          <label
            for="is_default"
            class="text-primary text-sm mb-4"
          >
            <input
              id="is_default"
              v-model="isDefault"
              type="checkbox"
            >
            Definir como padrão
          </label>
        </form>
      </template>

      <template slot="footer">
        <footer class="flex justify-between">
          <button
            class="button button--cancel !px-6"
            @click="dismiss"
          >
            Cancelar
          </button>

          <ui-button
            :disabled="$v.formData.$invalid || ui.isLoading"
            :width="'sm'"
            label="Adicionar"
            class="!px-6"
            @click="submit"
          />
        </footer>
      </template>
    </modal>
  </transition>
</template>

<script>
import { mapActions } from 'vuex';
import handleError from '@/mixins/handleError';
import handleSuccess from '@/mixins/handleSuccess';
import Modal from '@/components/ui/Modal.vue';
import formData from '@/data/user/payment';
import formUtils from '@/mixins/formUtils';
import UiField from '@/components/ui/form/_field.vue';
import UiButton from '@/components/ui/Button.vue';
import payment from '@/api/user/payment';

import visa from '@/assets/img/logo/credit_cards/visa.png';
import master from '@/assets/img/logo/credit_cards/mastercard.png';
import elo from '@/assets/img/logo/credit_cards/elo.png';
import american from '@/assets/img/logo/credit_cards/americaExpress.png';
import genericBrand from '@/assets/img/logo/credit_cards/genericCard.png';

export default {
  components: {
    Modal,
    UiField,
    UiButton,
  },

  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  mixins: [formUtils, handleError, handleSuccess],

  data() {
    return {
      formData,
      payment: null,
      isDefault: false,
      apiErrors: {},
      cardTypes: [genericBrand],
      ui: {
        confirmationActive: false,
        isLoading: false,
      },
    };
  },
  validations() {
    const obj = { formData: this.buildValidations(this.formData) };
    return obj;
  },
  watch: {
    sending(next) {
      if (!next) {
        this.$v.$reset();
      }
    },
    'formData.number.value': function (next) {
      switch (parseInt(next[0], 10)) {
        case 3: this.cardTypes = [american]; break;
        case 4: this.cardTypes = [visa]; break;
        case 5: this.cardTypes = [master, elo]; break;
        default: this.cardTypes = [genericBrand];
      }
    },
  },
  mounted() {
    this.payment = payment(this.$store.getters['user/headers']);
    for (const field in formData) {
      this.$set(this.apiErrors, field, []);
    }
  },
  methods: {
    ...mapActions('ui', [
      'toggleProgressBar',
    ]),

    dismiss(success) {
      this.formData = this.clearForm(this.formData);
      this.$v.$reset();
      this.$emit('closed', success);
    },

    // 4556366941062122 1220 Aardvark da Silva 111

    submit() {
      this.ui.isLoading = true;
      this.toggleProgressBar(true);
      const data = this.expandForm(this.formData);
      // data.cpf = data.cpf.replace(/[.-]/g, '');
      // delete data.cpf;
      data.isDefault = this.isDefault ? 'true' : 'false';
      data.expirationDate = `${(data.expirationMonth < 10 ? `0${data.expirationMonth}` : data.expirationMonth)}${`${data.expirationYear}`.substr(2, 2)}`;
      delete data.expirationMonth;
      delete data.expirationYear;
      this.payment.create(data).then(
        () => {
          this.handleSuccess('Cartão adicionado com sucesso');
          this.dismiss(1);
        },
      ).catch(
        (err) => {
          this.handleApiErrors(err, this.apiErrors);
          this.handleError(err.response.data.errors.errors.join('\n'));
        },
      ).finally(() => {
        this.ui.isLoading = false;
        this.toggleProgressBar(false);
      });
    },
  },
};
</script>
