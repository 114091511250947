// import { cpf } from 'cpf-cnpj-validator';
import { format } from 'date-fns';

const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];
const optMonths = [];
let i = 0;
for (const month of months) {
  i += 1;
  optMonths.push({
    value: i,
    label: month,
  });
}

const years = [];
for (let i = 2020; i <= 2040; i += 1) {
  years.push(
    {
      value: i,
      label: i,
    },
  );
}

const formData = {
  number: {
    class: 'w-full',
    type: 'text',
    name: 'number',
    label: 'Número do cartão',
    required: true,
    placeholder: 'Mês',
    mask: '#### #### #### ####',
    value: null,
  },
  expirationMonth: {
    class: '!w-5/12 mr-2 ',
    type: 'select',
    placeholder: 'Mês',
    name: 'expirationMonth',
    label: 'Expira em',
    value: null,
    options: optMonths,
    isValid(v) {
      if (!v || !formData.expirationYear.value) {
        return true;
      }
      if (parseInt(`${formData.expirationYear.value}${v < 10 ? `0${v}` : v}`, 10) < parseInt(format(new Date(), 'yyyyMM'), 10)) {
        return false;
      }
      return true;
    },
  },
  expirationYear: {
    size: 8,
    type: 'select',
    class: '!w-3/12',
    placeholder: 'Ano',
    name: 'expirationDate',
    label: ' ',
    value: null,
    options: years,
  },
  cvv: {
    class: '!w-3/12 ml-2',
    type: 'text',
    name: 'cvv',
    label: 'CVV',
    required: true,
    mask: '###',
    value: null,
    minlength: 3,
  },
  holderName: {
    type: 'text',
    name: 'holderName',
    label: 'Nome impresso no cartão',
    required: true,
    value: '',
  },

};
export default formData;
